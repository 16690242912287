import { PartUrl, UploadedPart, UploadFileUrls } from './uploadFileModel';

export interface ProgressData {
  sent: number;
  total: number;
  percentage: number;
}

interface ActiveConnections {
  [part: number]: XMLHttpRequest;
}

export class Uploader {
  static readonly CHUNK_SIZE: number = 1024 * 1024 * 5;
  private readonly threadsQuantity: number = 3;
  private aborted = false;
  private uploadedSize = 0;
  private readonly progressCache: number[] = [];
  private readonly activeConnections: ActiveConnections = {};
  private readonly uploadedParts: UploadedPart[] = [];

  constructor(
    readonly blob: Blob,
    readonly uploadFileUrls: UploadFileUrls,
    readonly onProgress: (progress: ProgressData) => void,
    readonly onComplete: (parts: UploadedPart[]) => void,
    readonly onError: (error: Error) => void,
  ) {}

  start() {
    this.sendNext();
  }

  sendNext() {
    const activeConnections = Object.keys(this.activeConnections).length;

    if (activeConnections >= this.threadsQuantity) {
      return;
    }

    if (!this.uploadFileUrls.urls.length) {
      if (!activeConnections) {
        this.complete();
      }

      return;
    }

    const part = this.uploadFileUrls.urls.pop();
    if (this.blob && part) {
      const sentSize = (part.part - 1) * Uploader.CHUNK_SIZE;
      const chunk = this.blob.slice(sentSize, sentSize + Uploader.CHUNK_SIZE);

      const sendChunkStarted = () => {
        this.sendNext();
      };

      this.sendChunk(chunk, part, sendChunkStarted)
        .then(() => {
          this.sendNext();
        })
        .catch((error) => {
          this.uploadFileUrls.urls.push(part);
          this.complete(error);
        });
    }
  }

  async complete(error?: Error) {
    if (error && !this.aborted) {
      this.onError(error);
      return;
    }

    if (error) {
      this.onError(error);
      return;
    }

    await this.onComplete(this.uploadedParts);
  }

  sendChunk(chunk: Blob, part: PartUrl, sendChunkStarted: () => void) {
    return new Promise<void>((resolve, reject) => {
      this.upload(chunk, part, sendChunkStarted)
        .then((status) => {
          if (status !== 200) {
            reject(new Error('Failed chunk upload'));
            return;
          }

          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  handleProgress(part: number, event: ProgressEvent) {
    if (this.blob) {
      if (
        event.type === 'progress' ||
        event.type === 'error' ||
        event.type === 'abort'
      ) {
        this.progressCache[part] = event.loaded;
      }

      if (event.type === 'uploaded') {
        this.uploadedSize += this.progressCache[part] || 0;
        delete this.progressCache[part];
      }

      const inProgress = Object.keys(this.progressCache)
        .map(Number)
        .reduce((memo, id) => (memo += this.progressCache[id]), 0);

      const sent = Math.min(this.uploadedSize + inProgress, this.blob.size);

      const total = this.blob.size;

      const percentage = Math.round((sent / total) * 100);

      this.onProgress({
        sent: sent,
        total: total,
        percentage: percentage,
      });
    }
  }

  upload(file: Blob, url: PartUrl, sendChunkStarted: () => void) {
    return new Promise((resolve, reject) => {
      const xhr = (this.activeConnections[url.part - 1] = new XMLHttpRequest());

      sendChunkStarted();

      const progressListener = this.handleProgress.bind(this, url.part - 1);

      xhr.upload.addEventListener('progress', progressListener);

      xhr.addEventListener('error', progressListener);
      xhr.addEventListener('abort', progressListener);
      xhr.addEventListener('loadend', progressListener);

      xhr.open('PUT', url.url);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          const ETag = xhr.getResponseHeader('ETag');

          if (ETag) {
            const uploadedPart = {
              number: url.part,
              ETag: ETag.replaceAll('"', ''),
            };

            this.uploadedParts.push(uploadedPart);

            resolve(xhr.status);
            delete this.activeConnections[url.part - 1];
          }
        }
      };

      xhr.onerror = (error) => {
        reject(error);
        delete this.activeConnections[url.part - 1];
      };

      xhr.onabort = () => {
        reject(new Error('Upload canceled by user'));
        delete this.activeConnections[url.part - 1];
      };

      xhr.send(file);
    });
  }

  abort() {
    Object.keys(this.activeConnections)
      .map(Number)
      .forEach((id) => {
        this.activeConnections[id].abort();
      });

    this.aborted = true;
  }
}
